import axiosInstance from "./CreateAxiosInstance";
import { handleApiError } from "../Utils/ApiUtils";

export default class PaymentApi {

  public static async createNewPayment(pay: any) {
    try {
      console.log('pay', pay);
      const details = await axiosInstance.post('/payments/create-payment', pay);
      return details.data;
    } catch (e) {
      throw handleApiError(e, 'Failed to create pay');
    }
  }
  public static async verifyPayment(pay: any) {
    try {
      console.log('pay', pay);
      const details = await axiosInstance.post('/payments/verify-payment', pay);
      return details.data;
    } catch (e) {
      throw handleApiError(e, 'Failed to create pay');
    }
  }
  public static async createPayment(pay: any) {
    try {
      console.log('pay', pay);
      const details = await axiosInstance.post('/payments/create-payment-details', pay);
      console.log("details", details)
      return details.data;
    } catch (e) {
      throw handleApiError(e, 'Failed to create pay');
    }
  }
  public static async getPaymentById(paymentId: string) {
    try {
      const detail = await axiosInstance.get<any>(`/payments/${paymentId}`);
      return detail.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get payment");
    }
  }
  public static async getRefundById(paymentId: string) {
    try {
      const detail = await axiosInstance.get<any>(`/payments/refund/${paymentId}`);
      return detail.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get payment");
    }
  }
  public static async triggerSuccessEmail(paymentId: string) {
    try {
      await axiosInstance.get<any>(`/email/send/${paymentId}`)
      return true
    } catch (e) {
      throw handleApiError(e, "Failed to trigger succcess mail");
    }
  }
}
