/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import AppContainer from "../CommonComponents/AppContainer";
import Book from "../assets/HomePageAssets/book.png";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import CardComponent from "./CardComponent";
import CarouselComponent from "./CarouselComponent";
import Colors from "../CommonComponents/Colors";
import EastIcon from "@mui/icons-material/East";
import OTPModal from "../pages/login/OTPModal";
import OverlayText from "../CommonComponents/CustomDateCalender/OverLayText";
import Play from "../assets/HomePageAssets/play.png";
import SearchImage from "../assets/HomePageAssets/search.png";
import SliderContainer from "../pages/aboutUs/AboutSlider";
import SportsSoccerTwoToneIcon from "@mui/icons-material/SportsSoccerTwoTone";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import assets from "../assets";
import backgroundimage from "./doodles-copy.jpg";
import badmontonImg from "./badminton-court.avif";
import book from "./book.svg";
import booknow from "../CommonFiles/Sports.svg";
import bowlingImage from "../assets/Bowling";
import cafeteriaImage from "../assets/Cafeteriya";
import cricketNetImage from "../assets/CricketNet";
import partycenterImage from "../assets/Partycenter";
import play from "./play.svg";
import routes from "../routes/routes";
import search from "./search.svg";
import styled from "styled-components";
import videoFile from "./Playing2.mp4";

const StyledImage = styled.img`
  @media (min-width: 300px) {
    width: 225px;
    height: 80px;
  }

  @media (min-width: 768px) {
    width: 225px;
    height: 80px;
  }
  @media (min-width: 820px) {
    width: 225px;
    height: 80px;
  }

  @media (min-width: 900px) {
    width: 225px;
    height: 80px;
  }

  @media (min-width: 1200px) {
    width: 340px;
    height: 100px;
  }
`;
const StyledImage1 = styled.img`
  @media (min-width: 300px) {
    width: 45px;
    height: 45px;
  }

  @media (min-width: 768px) {
    width: 45px;
    height: 45px;
  }
  @media (min-width: 820px) {
    width: 45px;
    height: 45px;
  }

  @media (min-width: 900px) {
    width: 45px;
    height: 45px;
  }

  @media (min-width: 1200px) {
    width: 70px;
    height: 70px;
  }
`;

const StyledImage2 = styled.img`

@media (min-width: 300px) {
  width: 320px;
  height: 325px;
}
@media (min-width: 414px) {
  width: 315px;
  height: 325px;
}

@media (min-width: 768px) {
  width: 325px;
  height: 325px;
}
@media (min-width: 820px) {
  width: 325px;
  height: 325px;
}

@media (min-width: 912px) {
  width: 388px;
  height: 370px;
}
@media (min-width: 1024px) {
  width: 388px;
  height: 370px;
}


@media (min-width: 1200px) {
  width: 345px;
  height: 345px;

  @media (max-width: 1280px) {
    width: 290px;
    height: 345px;
}
`;
const StyledVideo = styled.video`
  @media (min-width: 300px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @media (min-width: 414px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @media (min-width: 540px) {
    width: 490px;
    height: auto;
    margin: auto;
  }

  @media (min-width: 768px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @media (min-width: 820px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @media (min-width: 912px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media (min-width: 992px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @media (min-width: 1000px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media (min-width: 1200px) {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const SlideshowContainer = styled(Box)`
  width: 100%;
  max-width: 600px;
`;

const Slide = styled(Link)`
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  transition: transform 1s ease-in-out;
`;

const StyledImage3 = styled.img`
  border-radius: 10px;
  height: 350px;
  object-fit: cover;
`;

const {
  "pexels-guduru-ajay-bhargav-863988.jpg": Image3,
  "pexels-pixabay-209977.jog": Image4,
  "pexels-pixabay-46798.jpg": Image5,
  "pexels-pixabay-269630.jpg": boardGames,
  "pexels-vazhnik-7864443.jpg": playstation,
  "pexels-thiago-kai-9381737.jpg": turf,
  // "playzo.mp4": videoFile,
  "Playzo.png": logo,
  "instagram-ico.png": insta,
  "278453372_302010395428882_1782198866577334663_n.jpg": pic1,
  "292237898_605074144217428_6740391706468899652_n.jpg": pic2,
  "296017376_1229442621218307_3144906300765873571_n.jpg": pic3,
  "307209848_504498594836255_6289868240102465405_n.jpg": pic4,
} = assets;

const { "party1.webp": PartyCenterSlide1 } = partycenterImage;
const { "bowling2.jpg": BowlingSlide2 } = bowlingImage;

const { "crick2.jpg": CricketNetSlide2 } = cricketNetImage;

const { "cafe4.jpg": cafe } = cafeteriaImage;

const images = [Image3, Image4, Image5]; // List of images to loop through
const intervalTime = 5000; // Interval time in milliseconds

export default function HomePage() {
  const [showText, setShowText] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setShowText(true);
    }, 1000);

    const timer2 = setTimeout(() => {
      // Set showText to true for the second timer
      setInitialLoad(false); // Move this line here to set initialLoad to false after animation
    }, 2000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  useEffect(() => {
    if (!initialLoad) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, intervalTime);

      return () => clearInterval(interval);
    }
  }, [initialLoad]);
  const [loading, setLoading] = useState(true); // Set initial loading state to true

  useEffect(() => {
    // Simulate loading delay with setTimeout
    const timer = setTimeout(() => {
      setLoading(false); // After timeout, set loading state to false
    }, 3000); // Adjust timeout duration as needed

    return () => clearTimeout(timer); // Cleanup function to clear the timer
  }, []);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  const [openOtp, setOpenOtp] = useState(false);

  const { id } = useParams<{ id: string }>();

  const handleClose = () => {
    setOpenOtp(false);
  };

  useEffect(() => {
    // Regular expression to check if id is an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the URL contains an email address
    if (id && emailRegex.test(id)) {
      setOpenOtp(true);
    }
  }, [id]);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      if (video) {
        video.play().catch((error) => {
          console.log("Error attempting to play video:", error);
        });
      }
    };

    // Play video on initial load
    playVideo();

    // Play video on user interaction
    document.addEventListener("click", playVideo, { once: true });

    return () => {
      document.removeEventListener("click", playVideo);
    };
  }, []);

  const bookingflowItems = [
    {
      image: search,
      title: "Search",
      description:
        "Are you looking to play after work, organize your Sunday Five's football match? Explore the largest network of sports facilities whole over the India",
    },
    {
      image: book,

      title: "Book",
      description:
        "Once you’ve found the perfect ground, court or gym, Connect with the venue through the Book Now Button to make online booking & secure easier payment",
    },
    {
      image: play,

      title: "Play",
      description:
        "You’re the hero, you’ve found a stunning turf or court, booked with ease and now its time to play. The scene is set for your epic match.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const Instaimages = [
    {
      src: pic1,
      link: "https://www.instagram.com/p/CcZ6CBzJoOD/?igsh=MWFjOGltaWJ5NXBraA%3D%3D",
    },
    {
      src: pic2,
      link: "https://www.instagram.com/p/CfwI533r_-c/?igsh=MTFjbnIxcjY0bmZ1dw%3D%3D",
    },
    {
      src: pic3,
      link: "https://www.instagram.com/p/Cgln7h6JH7Y/?igsh=MWZrMDg2cncxYXV2dg%3D%3D",
    },
    {
      src: pic4,
      link: "https://www.instagram.com/p/CimdLmRJBfn/?igsh=MXBtem1iNm15cmhxZg%3D%3D",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === Instaimages.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

  // Calculate the scroll position based on the currentIndex
  const imageWidth = 200; // Width of each image
  const gapBetweenImages = 35; // Gap between images
  const totalScrollWidth =
    Instaimages.length * (imageWidth + gapBetweenImages) - gapBetweenImages;
  const scrollPosition = currentIndex * (imageWidth + gapBetweenImages);

  useEffect(() => {
    // Attempt to play the video programmatically
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        // Handle the error (autoplay was prevented)
        console.log("Autoplay was prevented:", error);
      });
    }
  }, []);

  useEffect(() => {
    // Ensure the video plays automatically on load
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <>
      <OverlayText />

      <Box width="100%">
        <StyledVideo ref={videoRef} autoPlay loop muted controls={false}>
          <source src={videoFile} type="video/mp4" />
        </StyledVideo>

        <AppContainer padding={0} maxWidth={1450}>
          <Box
            display={"flex"}
            gap={"15px"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexDirection={{ xs: "column", sm: "column", md: "row" }}
            padding={{ xs: "2rem", sm: "2rem", md: "5rem 20px" }}
          >
            {bookingflowItems.map((item, index) => (
              <Box
                maxWidth={450}
                key={index}
                alignItems={"center"}
                display={"flex"}
                flexDirection={"column"}
                gap={"15px"}
                justifyContent={"space-between"}
              >
                <img
                  style={{
                    maxWidth: 45,
                  }}
                  src={item.image}
                  alt="flows"
                />{" "}
                <Typography
                  fontSize={"24px"}
                  fontFamily={"Montserrat, sans-serif"}
                  color={"#060b1e"}
                  fontWeight={600}
                >
                  {item.title}
                </Typography>
                <Typography
                  fontWeight={300}
                  textAlign={"center"}
                  fontSize={"16px"}
                  fontFamily={"Montserrat, sans-serif"}
                  color={"#060b1e"}
                >
                  {item.description}
                </Typography>
              </Box>
            ))}
          </Box>
        </AppContainer>

        <Box margin={"0"} padding={{ xs: 0, sm: 0, md: "2rem 0" }}>
          <SliderContainer />
        </Box>
        {/* 
        <Box
          sx={{
            position: "relative",
            backgroundImage: `url(${backgroundimage})`,
            backgroundSize: "cover", // Fit the background image to cover the entire container
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            objectFit: "cover", // Set object-fit to cover
            height: "100%",
          }}
          width={"100%"}
          margin={"auto"}
          marginTop={"-10px"}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(255 255 255 / 96%)", // Adjust the alpha value (0.5 for 50% opacity)
            }}
          />

          <Box
            sx={{
              opacity: 1,
              zIndex: 1,
              position: "relative",
            }}
          >
            <AppContainer maxWidth={1450}>
              <Box
                display={"flex"}
                gap={"15px"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
                alignItems={"center"}
              >
                {bookingflowItems.map((item, index) => (
                  <Box
                    maxWidth={450}
                    key={index}
                    alignItems={"center"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"15px"}
                    justifyContent={"space-between"}
                  >
                    <img
                      style={{
                        maxWidth: 45,
                      }}
                      src={item.image}
                      alt="Search"
                    />
                    <Typography
                      fontSize={"24px"}
                      fontFamily={"Montserrat, sans-serif"}
                      color={"#060b1e"}
                      fontWeight={600}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      fontWeight={300}
                      textAlign={"center"}
                      fontSize={"16px"}
                      fontFamily={"Montserrat, sans-serif"}
                      color={"#060b1e"}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </AppContainer>
          </Box>

          <Stack
            sx={{
              opacity: 1,
              zIndex: 1,
              position: "relative",
            }}
            pt={{ xs: "30px", sm: "30px", md: "30px", lg: "60px" }}
          >
            <Box
              width={"24%"}
              border={`2px solid ${Colors.BUTTON_COLOR}`}
              marginLeft={{ xs: "29px", sm: "29px", md: "29px", lg: "59px" }}
              marginBottom={"10px"}
            ></Box>
            <Typography
              fontSize={{ xs: "22px", sm: "32px", md: "22px", lg: "32px" }}
              marginLeft={{ xs: "20px", sm: "20px", md: "20px", lg: "60px" }}
              fontWeight={700}
              color={Colors.BLACK}
              textTransform={"uppercase"}
              fontFamily={"Cormorant Garamond"}
            >
              Playzo 33
            </Typography>

            <Box width="100%" maxWidth={1200} paddingTop={"5px"} pb={"10px"}>
              <Typography
                variant="h2"
                color={Colors.BLACK}
                fontSize={{ xs: "16px", sm: "18px", md: "18px", lg: "24px" }}
                fontWeight={600}
                width={{ xs: "300px", sm: "662px", md: "770px", lg: "850px" }}
                marginLeft={{
                  xs: "27px",
                  sm: "27px",
                  md: "27px",
                  lg: "57px",
                }}
                // sx={{ fontFamily: "Inter" }}
                fontFamily={"Cormorant Garamond"}
              >
                We offer a diverse range of{" "}
                <Link
                  to={routes.BOOKING_SERVICE}
                  style={{ textDecoration: "none" }}
                >
                  <span style={{ color: Colors.BUTTON_COLOR }}>services</span>
                </Link>{" "}
                to cater to the varied interests of our community.
              </Typography>
            </Box>
          </Stack>
          <Grid
            sx={{
              opacity: 1,
              zIndex: 1,
              position: "relative",
            }}
            container
            width="100%"
            margin="0 auto"
            spacing={3}
            maxWidth={1460}
            flexDirection={{
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
            }}
          >
            <Grid
              sx={{
                margin: { xs: "10px", sm: "0px", md: "0px" },
              }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
            >
              <Link
                style={{
                  color: "black",
                }}
                to={routes.PLAY33_SERVIVICES}
              >
                <CardComponent
                  buttonLabel="Know more"
                  title={"Turf"}
                  description={
                    "Kick off your sports adventure on our two top-notch turfs, perfect for football, and a cricket selection for your sports."
                  }
                  image={turf}
                />
              </Link>
            </Grid>
            <Grid
              sx={{
                margin: { xs: "10px", sm: "0px", md: "0px" },
              }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
            >
              <Link
                style={{
                  color: "black",
                }}
                to={routes.PLAY33_SERVIVICES}
              >
                <CardComponent
                  buttonLabel="Know more"
                  title={"Badminton"}
                  description={
                    "Immerse yourself in the ultimate gaming experience with the latest consoles and a wide game selection for your sports."
                  }
                  image={badmontonImg}
                />
              </Link>
            </Grid>
            <Grid
              sx={{
                margin: { xs: "10px", sm: "0px", md: "0px" },
              }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
            >
              <Link
                style={{
                  color: "black",
                }}
                to={routes.PLAY33_SERVIVICES}
              >
                <CardComponent
                  buttonLabel="Know more"
                  title={"Cricket net"}
                  description={
                    "Explore timeless and modern board games, fostering laughter and friendly competition for a delightful time for your sports."
                  }
                  image={CricketNetSlide2}
                />
              </Link>
            </Grid>
            <Grid
              sx={{
                margin: { xs: "10px", sm: "0px", md: "0px" },
              }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
            >
              <Link
                style={{
                  color: "black",
                }}
                to={routes.PLAY33_SERVIVICES}
              >
                <CardComponent
                  buttonLabel="Know more"
                  title={"Bowling machine"}
                  description={
                    "Ace every shot on our professional Badminton courts, where passion meets a wide game and precision for your sports."
                  }
                  image={BowlingSlide2}
                />
              </Link>
            </Grid>
          </Grid>
          <Box
            sx={{
              opacity: 1,
              zIndex: 1,
              position: "relative",
            }}
            width="100%"
            margin="0 auto"
            pb={{ xs: "32px", sm: "32px", md: "32px", lg: "30px" }}
            pt={{ xs: "32px", sm: "32px", md: "32px", lg: "50px" }}
          >
            <Stack
              marginRight={{ xs: "25px", sm: "25px", md: "25px", lg: "0px" }}
              direction="row"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "end",
                lg: "end",
              }}
              alignItems="center"
            >
              <Link to={routes.BOOKING_SERVICE}>
                <Button
                  sx={{
                    color: Colors.BUTTON_COLOR,
                    textTransform: "uppercase",
                    padding: "10px 10px",
                    borderRadius: "30px",
                    fontSize: "16px",
                    fontWeight: 400,
                    border: "2px solid #15B5FC",

                    ":hover": {
                      background: Colors.BUTTON_COLOR,
                      color: Colors.WHITE,
                      border: "2px solid #15B5FC",
                    },
                    minWidth: 180,
                    width: "100%",
                    fontFamily: "Marcellus",
                  }}
                  variant="outlined"
                >
                  Book now
                </Button>
              </Link>
              <Box display={{ xs: "none", sm: "none", md: "block" }}>
                <img
                  width={"100%"}
                  style={{
                    maxWidth: 100,
                  }}
                  src={booknow}
                  alt="book"
                />
              </Box>
            </Stack>
          </Box>
        </Box> */}
        {/* 
        <Box
          sx={{
            position: "relative",
            backgroundImage: `url(${backgroundimage})`,
            backgroundSize: "cover", // Fit the background image to cover the entire container
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            objectFit: "cover", // Set object-fit to cover
            height: "100%",
          }}
          width={"100%"}
          margin={"auto"}
          marginTop={"-10px"}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(255 255 255 / 96%)", // Adjust the alpha value (0.5 for 50% opacity)
            }}
          />{" "}
          <Stack
            sx={{
              opacity: 1,
              zIndex: 1,
              position: "relative",
            }}
            pt={{ xs: "30px", sm: "30px", md: "30px", lg: "20px" }}
          >
            <Box
              width={"24%"}
              border={`2px solid ${Colors.BUTTON_COLOR}`}
              marginLeft={{ xs: "29px", sm: "29px", md: "29px", lg: "59px" }}
              marginBottom={"10px"}
            ></Box>
            <Typography
              fontSize={{ xs: "22px", sm: "32px", md: "22px", lg: "32px" }}
              marginLeft={{ xs: "20px", sm: "20px", md: "20px", lg: "50px" }}
              fontWeight={700}
              color={Colors.BLACK}
              // fontStyle={"italic"}
              textTransform={"uppercase"}
              fontFamily={"Cormorant Garamond"}
            >
              Zo’s Cafe
            </Typography>

            <Box width="100%" maxWidth={1200} paddingTop={"5px"} pb={"10px"}>
              {/* <StyledImage src={Layer2} alt="Layer2" /> 
              <Typography
                variant="h2"
                color={Colors.BLACK}
                fontSize={{ xs: "16px", sm: "18px", md: "18px", lg: "24px" }}
                fontWeight={600}
                width={{ xs: "300px", sm: "662px", md: "770px", lg: "850px" }}
                marginLeft={{
                  xs: "27px",
                  sm: "27px",
                  md: "27px",
                  lg: "57px",
                }}
                // sx={{ fontFamily: "Inter" }}
                fontFamily={"Cormorant Garamond"}
              >
                We offer a diverse range of{" "}
                <Link
                  to={routes.ZO_CAFE_SERVIVICES}
                  style={{ textDecoration: "none" }}
                >
                  <span style={{ color: Colors.BUTTON_COLOR }}>services</span>
                </Link>{" "}
                to cater to the varied interests of our community.
              </Typography>
            </Box>
          </Stack>
          <Grid
            sx={{
              opacity: 1,
              zIndex: 1,
              position: "relative",
            }}
            container
            width="100%"
            margin="0 auto"
            spacing={3}
            maxWidth={1460}
            flexDirection={{
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
            }}
          >
            <Grid
              sx={{
                margin: { xs: "10px", sm: "0px", md: "0px" },
              }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
            >
              <Link
                style={{
                  color: "black",
                }}
                to={routes.ZO_CAFE_SERVIVICES}
              >
                <CardComponent
                  buttonLabel="Know more"
                  title={"Play Station"}
                  description={
                    "Immerse yourself in the ultimate gaming experience with the latest consoles and a wide game selection for your sports."
                  }
                  image={playstation}
                />
              </Link>
            </Grid>
            <Grid
              sx={{
                margin: { xs: "10px", sm: "0px", md: "0px" },
              }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
            >
              <Link
                style={{
                  color: "black",
                }}
                to={routes.ZO_CAFE_SERVIVICES}
              >
                <CardComponent
                  buttonLabel="Know more"
                  title={"Board Games"}
                  description={
                    "Explore timeless and modern board games, fostering laughter and friendly competition for a delightful time for your sports."
                  }
                  image={boardGames}
                />
              </Link>
            </Grid>
            <Grid
              sx={{
                margin: { xs: "10px", sm: "0px", md: "0px" },
              }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
            >
              <Link
                style={{
                  color: "black",
                }}
                to={routes.ZO_CAFE_SERVIVICES}
              >
                <CardComponent
                  buttonLabel="Know more"
                  title={"Cafeteria"}
                  description={
                    "Ace every shot on our professional Badminton courts, where passion meets a wide game and precision for your sports."
                  }
                  image={cafe}
                />
              </Link>
            </Grid>
            <Grid
              sx={{
                margin: { xs: "10px", sm: "0px", md: "0px" },
              }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
            >
              <Link
                style={{
                  color: "black",
                }}
                to={routes.ZO_CAFE_SERVIVICES}
              >
                <CardComponent
                  buttonLabel="Know more"
                  title={"Party center"}
                  description={
                    "Kick off your sports adventure on our two top-notch turfs, perfect for football, and a cricket selection for your sports."
                  }
                  image={PartyCenterSlide1}
                />
              </Link>
            </Grid>
          </Grid>
          <Box
            sx={{
              opacity: 1,
              zIndex: 1,
              position: "relative",
            }}
            width="100%"
            margin="0 auto"
            pb={{ xs: "32px", sm: "32px", md: "32px", lg: "50px " }}
            pt={{ xs: "32px", sm: "32px", md: "32px", lg: "50px" }}
          >
            <Stack
              marginRight={{ xs: "25px", sm: "25px", md: "25px", lg: "30px" }}
              direction="row"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "end",
                lg: "end",
              }}
              alignItems="center"
              spacing={2}
            >
              <Link to={routes.BOOKING_SERVICE}>
                <Button
                  sx={{
                    color: Colors.BUTTON_COLOR,
                    textTransform: "uppercase",
                    padding: "10px 10px",
                    borderRadius: "30px",
                    fontSize: "14px",
                    fontWeight: 400,
                    minWidth: 200,
                    border: "2px solid #15B5FC",

                    ":hover": {
                      background: Colors.BUTTON_COLOR,
                      color: Colors.WHITE,
                      border: "2px solid #15B5FC",
                    },
                    fontFamily: "Marcellus",
                  }}
                  variant="outlined"
                >
                  Book now
                </Button>
              </Link>
              <EastIcon
                sx={{
                  color: Colors.BUTTON_COLOR,
                  fontSize: "34px",
                  display: { xs: "none", sm: "none", md: "block" },
                }}
              />
            </Stack>
          </Box>
        </Box> */}

        <Box width="100%">
          <Box padding={{ xs: "20px", sm: "20px", md: "0 50px" }}>
            <Typography
              fontSize={{ xs: "24px", sm: "24px", md: "26px", lg: "32px" }}
              fontWeight={700}
              color={Colors.BLACK}
              textAlign={"start"}
              textTransform={"none"}
              fontFamily={"lucoCleanBold"}
            >
              Playzo33{" "}
              <Typography
                fontSize={{ xs: "26px", sm: "26px", md: "26px", lg: "50px" }}
                fontWeight={700}
                color={Colors.BLACK}
                textAlign={"start"}
                textTransform={"none"}
                fontFamily={"lucoCleanBold"}
                sx={{
                  background:
                    "linear-gradient(90deg, #08CABE 0%,  #07DBF1 35%, #02A8C6 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text", // This is for non-webkit browsers (optional)
                  textFillColor: "transparent", // This is for non-webkit browsers (optional)
                }}
              >
                Insta Feeds
              </Typography>
            </Typography>
          </Box>

          <Box
            bgcolor={"aliceblue"}
            marginTop={"2rem"}
            display={{ xs: "none", sm: "none", md: "flex" }}
            justifyContent={"space-between"}
            padding={"20px 50px"}
            flexWrap={"wrap"}
            gap={{ xs: "20px", sm: "20px", md: "20px", lg: 0 }}
          >
            <Link
              target="_blank"
              to={
                "https://www.instagram.com/p/CcZ6CBzJoOD/?igsh=MWFjOGltaWJ5NXBraA%3D%3D"
              }
            >
              <StyledImage2
                style={{ borderRadius: "10px" }}
                src={pic1}
                alt=""
              />
            </Link>
            <Link
              target="_blank"
              to={
                "https://www.instagram.com/p/CfwI533r_-c/?igsh=MTFjbnIxcjY0bmZ1dw%3D%3D"
              }
            >
              <StyledImage2
                style={{ borderRadius: "10px" }}
                src={pic2}
                alt=""
              />
            </Link>
            <Link
              target="_blank"
              to={
                "https://www.instagram.com/p/Cgln7h6JH7Y/?igsh=MWZrMDg2cncxYXV2dg%3D%3D"
              }
            >
              <StyledImage2
                style={{ borderRadius: "10px" }}
                src={pic3}
                alt=""
              />
            </Link>
            <Link
              target="_blank"
              to={
                "https://www.instagram.com/p/CimdLmRJBfn/?igsh=MXBtem1iNm15cmhxZg%3D%3D"
              }
            >
              <StyledImage2
                style={{ borderRadius: "10px" }}
                src={pic4}
                alt=""
              />
            </Link>
          </Box>

          <Box
            mt={"30px"}
            ml={{ xs: 0, sm: "0px", md: "46px" }}
            pb={"30px"}
            display={{ xs: "flex", sm: "flex", md: "none" }}
            sx={{
              overflowX: "hidden",
              overflowY: "hidden",
              width: "100%",
            }}
          >
            <Box
              gap={"35px"}
              sx={{
                display: "flex",
                transform: `translateX(-${scrollPosition}px)`,
                transition: "transform 0.5s ease-out",
                width: `${totalScrollWidth}px`,
                maxWidth: "100%",
                marginLeft: "22px",
              }}
            >
              {Instaimages.map((item, index) => (
                <Box key={index} width={"86%"}>
                  <a href={item.link}>
                    <img
                      src={item.src}
                      height={"100%"}
                      width={"250px"}
                      alt={`Slide ${index}`}
                      style={{
                        borderRadius: "30px",
                        objectFit: "cover",
                      }}
                    />
                  </a>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      {id && openOtp ? (
        <OTPModal
          open={true}
          handleClose={handleClose}
          email={id}
          setOpenForgetModal={handleClose}
        />
      ) : (
        ""
      )}
    </>
  );
}
