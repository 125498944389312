const ROOT = "/";
const LOGIN = "/login";
const LOGOUT = "/logout";
const HOME = "/home";
const SERVICE = "/service";
const PLAYSTATION = SERVICE + "/playstation";
const CROSSFIT = SERVICE + "/crossfit";
const BADMINTON = SERVICE + "/badminton";
const TURF = SERVICE + "/turf";
const BOARDGAMES = SERVICE + "/boardgames";
const PARTYCENTER = SERVICE + "/partycenter";
const CAFETERIA = SERVICE + "/cafeteria";
const ABOUTUS = "/about-us";
const CONTACTUS = "/contact-us";
const USERPROFILE = "/user-profile";
const BOOKING_SERVICE = "/service-booking";
const TURF_BOOKING = "/turf-booking";
const PLAYSTATION_BOOKING = "/playstation-booking";
const BADMINTON_BOOKING = "/badminton-booking";
const BOARDGAME_BOOKING = "/boardgame-booking";
const CRICKETNET_BOOKING = "/cricketnet-booking";
const BOWLINGMACHINE_BOOKING = "/bowlingmachine-booking";
const PAYMENT_BOOKING = "/payment-booking";
const DATE_CALENDER = "/date-calender";
const IMAGE_GALLERY = "/image-gal";
const CAREERS_PAGE = "/careers-page";
const FAQ = "/faq-page";
const PRIVACY_POLICY = "/privacy-policy";

const CRICKETNET = SERVICE + "cricketnet";
const BOWLINGMACHINE = SERVICE + "bowlingmachine";
const TERMS_AND_CONDITIONS = "/terms-and-conditioins";
const COOKIES_PAGE = "/cookies";

const PLAY33_SERVIVICES = "/services/playzo33";
const ZO_CAFE_SERVIVICES = "/services/zo's-cafe";

const BOOKING_PDF = "/pdf-view";
const routes = {
  ROOT,
  ZO_CAFE_SERVIVICES,
  PLAY33_SERVIVICES,
  LOGIN,
  LOGOUT,
  HOME,
  SERVICE,
  PLAYSTATION,
  CROSSFIT,
  BADMINTON,
  TURF,
  BOARDGAMES,
  PARTYCENTER,
  CAFETERIA,
  ABOUTUS,
  CONTACTUS,
  USERPROFILE,
  BOOKING_SERVICE,
  TURF_BOOKING,
  PLAYSTATION_BOOKING,
  BADMINTON_BOOKING,
  BOARDGAME_BOOKING,
  CRICKETNET_BOOKING,
  BOWLINGMACHINE_BOOKING,
  PAYMENT_BOOKING,
  DATE_CALENDER,
  IMAGE_GALLERY,
  CRICKETNET,
  BOWLINGMACHINE,
  CAREERS_PAGE,
  FAQ,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  COOKIES_PAGE,
  BOOKING_PDF,
};

export default routes;
