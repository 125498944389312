import "./MissionValues.css";

import { Box, Typography } from "@mui/material";
import { useEffect, useRef } from "react";

import AbouFaQ from "./AboutFAQ";
import Colors from "../../CommonComponents/Colors";
import Gallery from "./WheelImage";
import OverlayText from "../../CommonComponents/CustomDateCalender/OverLayText";
import banner3 from "./Playzo 33.png";
import missionImage from "./bullseye.svg";
import valueImage from "./scale-svgrepo-com.svg";
import vissionImage from "./bulb-light-icon.svg";

export default function AboutUs() {
  const questionSectionRef = useRef(null);

  useEffect(() => {
    document.documentElement.scrollTop = 0;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("slide-up");
          }
        });
      },
      {
        threshold: 0.5, // Trigger when 50% of the section is visible
      }
    );

    if (questionSectionRef.current) {
      observer.observe(questionSectionRef.current);
    }

    return () => {
      if (questionSectionRef.current) {
        observer.unobserve(questionSectionRef.current);
      }
    };
  }, []);

  return (
    <>
      <OverlayText />

      <Box>
        <img
          width={"100%"}
          src={banner3}
          alt=""
          style={{
            objectFit: "cover",
            height: "auto",
            // maxHeight: "500px", // Ensures the image doesn't exceed 500px in height
          }}
        />
      </Box>
      <Box>
        <Box
          pt={{ xs: "30px", sm: "30px", md: "30px" }}
          pb={{ xs: "30px", sm: "30px", md: "30px" }}
          margin={"auto"}
        >
          <Box>
            <Box
              display={"flex"}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={"space-around"}
              mt={"30px"}
              gap={{ xs: "60px", md: "0px" }}
            >
              <Box>
                <div className="mission-vision-values">
                  <div className="mission-section">
                    <div className="mission-content">
                      <img src={missionImage} alt="" width={"60px"} />
                      <h3 style={{ fontFamily: "Montserrat, sans-serif" }}>
                        Mission
                      </h3>
                      <Typography
                        textAlign={"center"}
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-18px",
                          fontSize: "11px",
                        }}
                      >
                        A mission paragraph, also known as a mission statement,
                        is a brief statement that explains a company's purpose,
                        values, and goals.
                      </Typography>
                    </div>
                  </div>
                </div>
              </Box>
              <Box>
                <div className="mission-vision-values">
                  <div className="mission-section">
                    <div className="mission-content">
                      <img src={vissionImage} alt="" width={"60px"} />

                      <h3 style={{ fontFamily: "Montserrat, sans-serif" }}>
                        Vision
                      </h3>
                      <Typography
                        textAlign={"center"}
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-18px",
                          fontSize: "11px",
                        }}
                      >
                        Vision is the process where your eyes and brain work
                        together and use light reflecting off things around you
                        to create the ability to see.
                      </Typography>
                    </div>
                  </div>
                </div>
              </Box>
              <Box pb={{ xs: "35px", md: "0px" }}>
                <div className="mission-vision-values">
                  <div className="mission-section">
                    <div className="mission-content">
                      <img src={valueImage} alt="" width={"60px"} />

                      <h3 style={{ fontFamily: "Montserrat, sans-serif" }}>
                        Values
                      </h3>
                      <Typography
                        textAlign={"center"}
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-18px",
                          fontSize: "11px",
                        }}
                      >
                        Values are individual beliefs that motivate people to
                        act one way or another. They serve as a guide for human
                        behavior.
                      </Typography>
                    </div>
                  </div>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box maxWidth={1200}>
        <Typography
          sx={{
            position: "absolute",
            left: { xs: "8%", md: "15%" },
          }}
          mt={"2rem"}
          fontSize={{ xs: "28px", md: "32px" }}
          fontFamily={"lucoCleanBold"}
        >
          Playzo33 Timeline <br />{" "}
          <Typography
            color={"white"}
            fontSize={{ xs: "28px", md: "32px" }}
            fontFamily={"lucoCleanBold"}
          >
            Journey
          </Typography>
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(90deg, #08CABE 0%,  #07DBF1 35%, #02A8C6 100%)",
        }}
      >
        {" "}
        <Box
          sx={{
            display: "flex",
            //  justi: center;
            //  align-items: center;
            //  min-height: 100vh;
            //  background: #000;
            justifyContent: "center",
            alignItems: "center",
            minHeight: { xs: "85vh", sm: "60vh", md: "100vh" },
          }}
        >
          <Gallery />
        </Box>
      </Box>

      {/* <CircularUI /> */}
      {/* <TimeLineSlider /> */}

      <Box
        margin={" 2rem auto"}
        maxWidth={1200}
        width={{ xs: "85%", sm: "85%", md: "100%" }}
        ref={questionSectionRef} // Reference to the question section
        className="questions-container" // Add class for animation
      >
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          <Box marginBottom={"20px"}>
            <Typography
              fontFamily={"lucoCleanBold"}
              fontWeight={500}
              fontSize={{ xs: "24px", sm: "24px", md: "32px" }}
              textAlign={"start"}
              color={Colors.BUTTON_COLOR}
            >
              <span
                style={{
                  color: "black",
                }}
              >
                Questions?
              </span>{" "}
              Look here.
            </Typography>
          </Box>

          <AbouFaQ />
          <Typography
            fontFamily={"Montserrat, sans-serif"}
            textAlign={"center"}
            fontSize={"18px"}
          >
            <span
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              Note :{" "}
            </span>
            Can't find an answer? Call us at{" "}
            <a
              style={{
                color: "black",
              }}
              href="tel:7094460944"
            >
              +91 7094460944
            </a>{" "}
            /{" "}
            <a
              style={{
                color: "black",
              }}
              href="tel:9108883555"
            >
              +91 91088 83555
            </a>
            <span> or email {""}</span>
            <a
              style={{
                color: "black",
              }}
              href="mailto:playzo.bookings@gmail.com"
            >
              playzo.bookings@gmail.com
            </a>
            !
          </Typography>
        </Box>{" "}
      </Box>
    </>
  );
}
