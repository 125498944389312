import "./Gallery.css"; // Import your styles from a separate CSS file

import {
  default as cafe,
  default as image4,
} from "./SpinerImages/cafeteria_service1.avif";

import launch from "./../../BookingService/Playzo.png";
import image2 from "./ground_turf.jpg";
import playstation from "./ServiceImages/playstation_service.webp";
import image3 from "./ServiceImages/Turf_service.jpg";
import badminton from "./SpinerImages/badcourt_1.avif";

// import turf from "./Turf_service.jpg";

// import image1 from "./SpinerImages/playzo_launch1.png";

const Gallery = () => {
  return (
    <div className="gallery">
      <span
        style={{
          top: "40%",
        }}
      >
        <img
          style={{
            width: "100%",
            height: "auto",
          }}
          src={launch}
          alt="Banner"
        />
      </span>
      <span>
        <img src={image3} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2019,Oct</h1>
          <p
            style={{
              fontSize: "12px",
              margin: 0,
            }}
          >
            Playzo Launch
          </p>
        </div>
      </span>
      <span>
        <img src={playstation} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2020,Jul</h1>
          <p
            style={{
              fontSize: "12px",
              margin: 0,
            }}
          >
            Playzo Turf 2.0 launch
          </p>
        </div>
      </span>
      <span>
        <img src={cafe} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2020,Dec</h1>
          <p
            style={{
              fontSize: "12px",
              margin: 0,
            }}
          >
            Playzo Turf 3.0 launch
          </p>
        </div>
      </span>

      <span>
        <img src={badminton} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2021,Aug</h1>
          <p
            style={{
              fontSize: "12px",
              margin: 0,
            }}
          >
            Playzo 4.0 Cafeteria Launch
          </p>
        </div>
      </span>

      <span>
        <img src={image4} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2023,Feb</h1>
          <p
            style={{
              fontSize: "12px",
              margin: 0,
            }}
          >
            Playzo 5.0 - 4 Badminton Court Launch
          </p>
        </div>
      </span>
      <span>
        <img src={image2} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2022,Jul</h1>
          <p className="playzopara">Playzo Board Games launch</p>
        </div>
      </span>
      <span>
        <img src={image4} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2021,Aug</h1>
          <p
            style={{
              fontSize: "12px",
              margin: 0,
            }}
          >
            Playzo 4.0 Cafeteria Launch
          </p>
        </div>
      </span>
      <span>
        <img src={badminton} alt="Banner" />
        <div className="inner_text">
          <h1 className="datetext">2021,Aug</h1>
          <p
            style={{
              fontSize: "12px",
            }}
          >
            Playzo 4.0 Cafeteria Launch
          </p>
        </div>
      </span>
    </div>
  );
};

export default Gallery;
