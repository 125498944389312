/* eslint-disable jsx-a11y/alt-text */

import { Box, Divider, Stack, Typography } from "@mui/material";

import { BookingNameTypes } from "../../BookingService/BookingSubTypes";
import { BookingType } from "../../CommonFiles/BookingType";
import Colors from "../../CommonComponents/Colors";
import DateUtils from "../../Utils/DateUtils";
import ModalComponent from "../../CommonComponents/CustomDateCalender/ModalComponent";
import PaymentApi from "../../api/PaymentApi";
import TimeUtlis from "../../Utils/TimeUtlis";
import { useState } from "react";

interface DataProps {
  userDetails: any[];
  userName: string;
}
const ListCard = ({ userDetails, userName }: DataProps) => {
  const [refuntSucess, setRefuntSucess] = useState(false);

  const CapitalizeFirstLetter = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getDayName = (dateString: any) => {
    const date = new Date(dateString);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[date.getDay()];
  };
  // const onclick = async (id: string) => {
  //   try {
  //     await PaymentApi.getRefundById(id)
  //       .then((data) => {
  //         setRefuntSucess(true);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <>
      {userDetails.map((user, index) => (
        <Stack direction="column" spacing={2} key={index} py={2}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                fontFamily={"Montserrat, sans-serif"}
                fontWeight={600}
                fontSize={"16px"}
              >
                Booking id.
              </Typography>
              <Typography
                fontFamily={"Montserrat, sans-serif"}
                fontSize={"16px"}
                fontWeight={900}
              >
                {user.id}
              </Typography>
            </Stack>
          </Stack>
          <Typography
            fontFamily={"Montserrat, sans-serif"}
            variant="body2"
            fontWeight={800}
          >
            {/* {DateUtils.formatDate(user.startDate, "DD-MMM-YYYY")} */}
            {new Date(user.startDate).toLocaleDateString("en-US", {
              month: "short", // 'Sep'
              day: "numeric", // '10'
            })}
            , ({getDayName(user.startDate)}){" "}
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row", md: "row", lg: "row" }}
            spacing={{ xs: 2, sm: 5, md: 5, lg: 5 }}
            alignItems="start"
          >
            <Box display={"flex"} justifyContent={"space-between"} gap={"2rem"}>
              <Typography
                fontWeight={800}
                fontFamily={"Montserrat, sans-serif"}
                fontSize={"16px"}
              >
                {userName}
              </Typography>
              <Box display={"flex"}>
                <Typography
                  fontWeight={800}
                  fontFamily={"Montserrat, sans-serif"}
                  fontSize={"16px"}
                >
                  {CapitalizeFirstLetter(user.type)}
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} gap={"2rem"}>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <Typography
                  fontWeight={800}
                  fontFamily={"Montserrat, sans-serif"}
                  fontSize={"16px"}
                >
                  {CapitalizeFirstLetter(
                    BookingNameTypes[
                      (user.type + user.court) as keyof typeof BookingNameTypes
                    ]
                  )}
                </Typography>
                <Typography
                  fontWeight={800}
                  fontFamily={"Montserrat, sans-serif"}
                  paddingLeft={"5px"}
                  fontSize={"16px"}
                >
                  {user.type === BookingType.Badminton ? (
                    <Typography>/ {user.numberOfPerson}</Typography>
                  ) : (
                    ""
                  )}
                </Typography>
              </Box>

              <Typography
                fontWeight={800}
                fontFamily={"Montserrat, sans-serif"}
                fontSize={"16px"}
              >{`${TimeUtlis.formatMillisecondsToTimeConvert(
                user.startTime
              )} - ${TimeUtlis.formatMillisecondsToTimeConvert(
                user.endTime
              )}`}</Typography>
            </Box>
          </Stack>
          {/* <Stack
            direction="row"
            spacing={1}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={3}
              alignItems="center"
              sx={{
                display: "flex",

                flexWrap: "wrap", // Add flexWrap property
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Button
                  variant="outlined"
                  sx={{
                    padding: "8px 20px",
                    textTransform: "none",
                    fontSize: {
                      xs: "12px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                    },
                    maxWidth: "110px",
                    fontWeight: "400",
                    border: "2px solid #15B5FC",
                    borderRadius: "30px",
                    letterSpacing: "1.6px",
                    background: Colors.BUTTON_COLOR,
                    color: Colors.WHITE,
                    "&:hover": {
                      background: Colors.WHITE,
                      color: Colors.BUTTON_COLOR,
                      border: "2px solid #15B5FC",
                    },
                    fontFamily: "Montserrat, sans-serif",
                  }}
                  disabled={user.isRefund}
                  onClick={() => onclick(user.id)}
                >
                  Refund
                </Button>
              </Stack>
            </Stack>
          </Stack> */}
          <Divider variant="fullWidth" sx={{ borderColor: Colors.BLACK }} />
        </Stack>
      ))}

      <ModalComponent
        open={refuntSucess}
        handleClose={() => setRefuntSucess(false)}
        text={"Your refund request has beed  successfully completed"}
        headingText={"Refund successfully"}
      />
    </>
  );
};

export default ListCard;
