import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import Colors from "../../CommonComponents/Colors";
import CustomTextField from "../../CommonComponents/CustomTextField";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalComponent from "../../CommonComponents/CustomDateCalender/ModalComponent";
import OverlayText from "../../CommonComponents/CustomDateCalender/OverLayText";
import assets from "../../assets/index";
import image from "./image (1).png";
import layer from "../Terms&conditions/Layer.svg";
import styled from "@emotion/styled";

const { "cookies.jpg": banner } = assets;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

// const StyledImage = styled.img`
//   @media (min-width: 300px) {
//     width: 340px;
//     height: 340px;
//     margin: auto;
//     display: none;
//   }
//   @media (min-width: 414px) {
//     width: 370px;
//     height: 340px;
//     margin: auto;
//     display: none;
//   }
//   @media (min-width: 540px) {
//     width: 490px;
//     height: 340px;
//     margin: auto;
//     display: none;
//   }

//   @media (min-width: 768px) {
//     width: 700px;
//     height: 440px;
//     margin: auto;
//     display: flex;
//   }
//   @media (min-width: 820px) {
//     width: 730px;
//     height: 440px;
//     margin: auto;
//     display: flex;
//   }
//   @media (min-width: 912px) {
//     width: 830px;
//     height: 440px;
//     margin: auto;
//   }

//   @media (min-width: 992px) {
//     width: 600px;
//     height: 600px;
//     margin: auto;
//     display: flex;
//   }
//   @media (min-width: 1000px) {
//     width: 600px;
//     height: 600px;
//     margin: auto;
//     display: flex;
//   }

//   @media (min-width: 1200px) {
//     width: 600px;
//     height: 600px;
//     display: flex;
//   }
// `;
export default function CareersPageNew() {
  const user = localStorage.getItem("user");
  const userData = user && JSON.parse(user);
  const [name, setName] = useState(userData ? userData["name"] : "");
  const [email, setEmail] = useState(userData ? userData["email"] : "");
  const [phoneNumber, setPhoneNumber] = useState(
    userData ? userData["phone"] : ""
  );
  const [successMessage, setSuccessMessage] = useState(false);

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [role, setRole] = useState(userData ? userData["name"] : "");

  const handleNameChange = (event: any) => {
    setName(event);
    setIsValidName(false);
  };

  const handleRoleChange = (event: any) => {
    setRole(event);
  };
  const handleEmailChange = (event: any) => {
    setEmail(event);
    setIsValidEmail(false);
  };

  const validateEmail = (input: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(input);
    return isValid;
  };

  const handlePhoneChange = (event: any) => {
    setPhoneNumber(event);
    setIsValidPhone(false);
  };

  const successNotification = () => {
    setSuccessMessage(false);
  };

  const validatePhone = (input: any) => {
    const phonePattern = /^\d{10}$/;
    const isValid = phonePattern.test(input);
    return isValid;
  };

  const onSubmit = async (event: any) => {
    event?.preventDefault();

    if (!name) {
      setIsValidName(true);
    }
    if (!email) {
      setIsValidEmail(true);
    }
    if (!phoneNumber) {
      setIsValidPhone(true);
    }

    if (!validateEmail(email)) {
      setIsValidEmail(true);
      return;
    }
    if (!validatePhone(phoneNumber)) {
      setIsValidPhone(true);
      return;
    }
    if (!resume) {
      setIsValidResume(true);
      return;
    }
    const data = {
      name: name,
      email: email,
      role: role,
      phoneNumber: phoneNumber,
      resume: resume,
    };
    console.log(data);
  };

  const BannerImage = styled.img`
    @media (min-width: 300px) {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }

    @media (min-width: 768px) {
      width: 100%;
      height: 282px;
      object-fit: cover;
    }
    @media (min-width: 820px) {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
    }

    @media (min-width: 912px) {
      height: 379px;
      width: 100%;
      object-fit: cover;
    }

    @media (min-width: 1200px) {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
    }
  `;
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  const [resume, setResume] = useState(null);
  const [isValidResume, setIsValidResume] = useState(false);

  const handleResumeChange = (event: any) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setResume(selectedFile);
      setIsValidResume(false);
    }
  };

  const isHideBannerImage = useMediaQuery(
    "(min-width: 280px) and (max-width: 900px)"
  );
  const StyledImage5 = styled.img`
    @media (min-width: 300px) {
      max-height: 400px;
      object-fit: cover;
      position: absolute;
      transform: translate(1300%, 0px);
    }

    @media (min-width: 768px) {
      max-height: 400px;
      object-fit: cover;
      position: absolute;
      transform: translate(679%, -117px);
    }
    @media (min-width: 820px) {
      max-height: 400px;
      object-fit: cover;
      position: absolute;
      transform: translate(726%, -117px);
    }

    @media (min-width: 900px) {
      max-height: 400px;
      object-fit: cover;
      position: absolute;
      transform: translate(1020%, -21px);
    }

    @media (min-width: 1200px) {
      max-height: 400px;
      object-fit: cover;
      position: absolute;
      transform: translate(1300%, -21px);
    }
  `;
  return (
    <>
      <OverlayText />
      <Box>
        <Container>
          {isHideBannerImage ? null : <BannerImage src={banner} alt="banner" />}
          <Box
            sx={{
              background:
                "#15b5fc linear-gradient(45deg, #2196f3 30%, #21cbf3 70%)",
              maxWidth: { xs: "100%", sm: "100%", md: "603px", lg: "800px" },
              width: { xs: "100%", sm: "100%" },
              height: { xs: "250px", sm: "282px", md: "380px", lg: "379px" },
              position: "absolute",
            }}
          >
            {" "}
          </Box>
          {isHideBannerImage ? null : (
            <StyledImage5 src={layer} alt="background" />
          )}
        </Container>
        <Box
          position={"absolute"}
          // top={{ xs: "22%", sm: "15%", md: "17%", lg: "25%" }}
          left={{ xs: "5%", sm: "5%", md: "9%" }}
          pt={"30px"}
        >
          <Typography
            display={"flex"}
            flexDirection={"column"}
            gap={"10px"}
            position={"absolute"}
            top={{ xs: "460%", sm: "500%", md: "-700%" }}
            lineHeight={1}
            fontFamily={"lucoCleanBold"}
            fontWeight={500}
            fontSize={{ xs: "45px", md: "85px" }}
            color={"Black"}
            textTransform={"uppercase"}
          >
            Careers
          </Typography>
        </Box>

        <Stack
          maxWidth={1200}
          margin={"auto"}
          mt={{ xs: "15rem", sm: "18rem", md: "2rem" }}
          style={{
            marginBottom: "2rem",
          }}
          paddingBottom={{ xs: 0, sm: 0, md: "2rem" }}
        >
          <Box>
            <Typography
              lineHeight={1}
              padding={{
                xs: "30px 20px 0px",
                sm: "20px 0px 10px",
                md: "20px",
                lg: "20px 0",
              }}
              textTransform={"uppercase"}
              fontSize={{ xs: "25px", sm: "32px", md: "50px" }}
              sx={{
                fontWeight: "bold",
                textAlign: {
                  xs: "justify",
                  sm: "center",
                  md: "center",
                  lg: "justify",
                },
                color: "black",
              }}
              fontFamily={"Montserrat, sans-serif"}
              marginBottom={{ xs: 0, sm: 2, md: 0 }}
            >
              Welcome To Careers!!!
            </Typography>
            <Stack
              direction={{
                xs: "column", // Stack vertically on small screens
                sm: "column",
                md: "column",
                lg: "row", // Horizontal layout on larger screens
              }}
              spacing={10}
              paddingBottom={{
                xs: "0px",
                sm: "60px",
                md: "60px",
                lg: "0px",
              }}
              gap={"2rem"}
            >
              {/* Image Section */}
              <Box
                display={{ xs: "none", sm: "none", md: "none", lg: "flex" }}
                justifyContent="center" // Center image on smaller screens
                marginLeft={{ xs: "0px", sm: "0px", md: "0px", lg: "0px" }}
                sx={{
                  width: { xs: "100%", lg: "50%" }, // Full width on small screens, half width on large screens
                  paddingTop: { xs: "0px", sm: "0px", md: "0px", lg: "0px" },
                }}
              >
                <img
                  src={image}
                  alt=""
                  style={{
                    maxWidth: "100%", // Make image responsive
                    height: "auto",
                  }}
                />
              </Box>

              {/* Form Section */}
              <Box
                width={{ xs: "100%", lg: "50%" }} // Full width on small screens, half width on large screens
                pt={{ xs: "0px", sm: "0px", md: "0px", lg: "10px" }}
                marginTop={"20px !important"}
                marginLeft={"0px !important"}
              >
                <form onSubmit={onSubmit}>
                  <Stack
                    paddingBottom={"40px"}
                    sx={{
                      marginTop: {
                        xs: "0px",
                        sm: "0px",
                        md: "0px",
                        lg: "-34px",
                      },
                    }}
                    margin={{
                      xs: "0 auto",
                      sm: "0 auto",
                      md: "0 auto",
                      lg: "0 auto",
                    }}
                    border={{
                      xs: "1px solid #d4ebf2",
                      sm: "1px solid #d4ebf2",
                      md: "1px solid #d4ebf2",
                      lg: "1px solid #d4ebf2",
                    }}
                    width={{ xs: "80%", sm: "88%", md: "80%", lg: "100%" }}
                    borderRadius={{
                      xs: "20px",
                      sm: "20px",
                      md: "20px",
                      lg: "20px",
                    }}
                    padding={{
                      xs: "21px 16px 40px",
                      sm: "21px 16px",
                      md: "21px 16px",
                      lg: "10px 21px 40px",
                    }}
                    direction="column"
                    spacing={3}
                    style={{
                      backgroundColor: "rgb(231 231 231)",
                    }}
                  >
                    {/* Form Fields */}
                    <Box
                      mb={"30px"}
                      display={"flex"}
                      flexDirection={"column"}
                      gap={"15px"}
                    >
                      <CustomTextField
                        variant="standard"
                        sx={{ maxWidth: 700, borderRadius: "8px" }}
                        label="Name"
                        placeholder="Enter your name"
                        value={name}
                        required={false}
                        onChange={handleNameChange}
                        error={!!isValidName}
                        helperText={
                          isValidName ? "Please provide valid Name" : ""
                        }
                      />
                      <CustomTextField
                        required={false}
                        variant="standard"
                        sx={{ maxWidth: 700, borderRadius: "8px" }}
                        label="Email"
                        placeholder="Enter your email address"
                        value={email}
                        onChange={handleEmailChange}
                        error={!!isValidEmail}
                        helperText={
                          isValidEmail ? "Please provide valid Email" : ""
                        }
                      />
                      <CustomTextField
                        required={false}
                        variant="standard"
                        sx={{ maxWidth: 700, borderRadius: "8px" }}
                        label="Role you want to apply"
                        placeholder="Role you want to apply"
                        value={role}
                        onChange={handleRoleChange}
                      />
                      <CustomTextField
                        required={false}
                        variant="standard"
                        sx={{ maxWidth: 700, borderRadius: "8px" }}
                        label="Phone Number"
                        placeholder="Enter your phone number"
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        type="number"
                        error={!!isValidPhone}
                        helperText={
                          isValidPhone
                            ? "Please provide valid phone number"
                            : ""
                        }
                      />
                      <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                        <Typography fontFamily={"Montserrat, sans-serif"}>
                          Attachments:
                        </Typography>
                        {resume ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "3px",
                            }}
                          >
                            <Typography
                              fontFamily={"Montserrat, sans-serif"}
                              color={Colors.BUTTON_COLOR}
                            >
                              {(resume as File)?.name}
                            </Typography>
                            <IconButton
                              onClick={() => setResume(null)}
                              style={{ marginLeft: "10px" }}
                            >
                              <DeleteIcon
                                style={{
                                  color: "red",
                                }}
                              />
                            </IconButton>
                          </div>
                        ) : (
                          <>
                            <IconButton
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "10px",
                              }}
                            >
                              <label htmlFor="resumeInput">
                                <AttachFileIcon
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    color: Colors.BUTTON_COLOR,
                                  }}
                                />
                              </label>
                              <input
                                id="resumeInput"
                                type="file"
                                accept=".pdf, .doc, .docx"
                                onChange={handleResumeChange}
                                style={{
                                  display: "none",
                                }}
                              />
                            </IconButton>
                            {isValidResume && (
                              <p
                                style={{
                                  color: "red",
                                  fontFamily: "Montserrat, sans-serif",
                                }}
                              >
                                Please attach a valid resume file.
                              </p>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                    <Button
                      sx={{
                        borderRadius: "30px",
                        textTransform: "none",
                        fontSize: "16px",
                        fontWeight: 400,
                        padding: "10px",
                        maxWidth: 700,
                        border: "1px solid #15B5FC",
                        background:
                          "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                        color: Colors.WHITE,
                        fontFamily: "Montserrat, sans-serif",
                        ":hover": {
                          background: Colors.WHITE,
                          color: Colors.BUTTON_COLOR,
                          border: "1px solid #15B5FC",
                        },
                      }}
                      variant="outlined"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Stack>
                </form>
                <ModalComponent
                  open={successMessage}
                  handleClose={successNotification}
                  text="Your Enquiry is Successfully added"
                  headingText="Enquiry Confirmation"
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
